body {
	animation-name:dim;
	animation-duration:2s;
	animation-timing-function:linear;
	animation-iteration-count:1;
	/* Safari and Chrome: */
	-webkit-animation-name:dim;
	-webkit-animation-duration:2s;
	-webkit-animation-timing-function:linear;
	-webkit-animation-iteration-count:1;
}

.portal {
    font-family: "Helvetica Neue", "open sans", Arial, sans-serif;
    font-size: 16px;
    margin: 0px 0px 0px 0px;
    position: relative;
    box-sizing: content-box;
}

@keyframes dim {
	0%   {background:#eeeeee;}
	100% {background:#white;}
}

@-webkit-keyframes dim {
	0%   {background:#eeeeee;}
	100% {background:#white;}
}

.portal br {
	font-size: 16px;
}

.portal h1 {
	font-family: Impact, Verdana, "Arial Black", sans-serif;
	font-size: 20px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
    margin-top: 13.4px !important;
}

.portal h2 {
	font-family: Impact, Verdana, "Arial Black", sans-serif;
	font-size: 20px;
	padding: 0px 15px 0px 15px;
}

.portal p {
	font-size: 16px;
	padding: 0px 18px 0px 18px;
}

.portal .big-window {
    background-color: #eeeeee !important;
	position: fixed;
	top: 50%;
	left: 50%;
	height: 510px;
	width: 510px;
	margin-top: -255px;
	margin-left: -255px;
	padding: 11px 11px 11px 11px;
	background-color: white;
	border-radius: 3px;
	z-index: 1;
	font-size: 0px;	
    line-height: 0;
	transition: top 1s ease-out, left 1s ease-in;
	-webkit-transition: top 1s ease-out, left 1s ease-in;
}

@media only screen and (max-width: 900px), only screen and (max-device-width: 900px) {
    .portal .big-window {
    	top: 50px;
    	left: 0px;
    	margin-top: 100px;
		margin-left: 0px;
		z-index: 0;

		transition: top 1s ease-in, left 1s ease-out;
		-webkit-transition: top 1s ease-in, left 1s ease-out;
    }

    body {
    	animation-duration:0s;
		-webkit-animation-duration:0s;
    }
}


@media only screen and (max-width: 570px), only screen and (max-device-width: 570px), only screen and (max-width: 900px) and (max-height: 582px), only screen and (max-device-width: 900px) and (max-height: 582px) {
    .portal .big-window {
    	position: static;
    	width: 340px;
    	height: 680px;
    	margin: 50px auto 0px auto;
    }

    .portal .about-window {
    	display: none !important;
    }

    #portal-view {
    	margin-top: 150px;
    }

    #about-view {
    	height: auto;
    	padding-bottom: 30px !important;
    	display: block;
    	margin-bottom: 50px !important;
    }
}

/*@media only screen and (max-width: 362px), only screen and (max-device-width: 362px) {*/
@media only screen and (max-width: 362px), only screen and (max-device-width: 362px) {
    .portal .big-window {
    	width:90%;
    }

    #portal-view {
    	height:0px;
    	padding-bottom:185%;
    }

    .portal .window {
    	width: 42% !important;
    	margin: 4% 4% 4% 4% !important;
    	height: 0px !important;
    	padding-bottom: 42% !important;
    }
}

.portal .window {
	width: 150px;
	height: 150px;
	margin: 10px 10px 10px 10px;
	overflow: hidden;
	display: inline-block;
	position: relative;
	border-radius: 3px;
	border: 0px;
}

.portal .window img {
	min-height: 100%;
	max-height: 100%;
}

.portal .window:hover > .category-title {
	font-size: 24px;
}

.portal .about-window {
	font-family: Impact, Verdana, "Arial Black", sans-serif;
	font-size: 16px;
	background-color: #111111;
	color: white;
	text-decoration: none;
	transition: background-color 0.3s, font-size 0.3s ease-out;
	-webkit-transition: background-color 0.3s, font-size 0.3s ease-out;
	-moz-transition: background-color 0.3s, font-size 0.3s ease-out;
	-ms-transition: background-color 0.3s, font-size 0.3s ease-out;
	-o-transition: background-color 0.3s, font-size 0.3s ease-out;
	text-align: right;
	
	position: absolute;
	display: block;
	bottom: 11px;
	right: 11px;
}

.portal .back-window {
	text-align: left;
}

.portal a {
	color: #4C21C2;
	text-decoration: none;
}

.portal a:hover {
	color: #4C21C2;
	text-decoration: underline;
}

.portal a:active {
	color: #FF8800;
}

.portal .about-window:hover {
	background-color: #4C21C2;
	font-size: 24px;
	cursor: pointer;
}

.portal .category-title {
	font-family: Impact, Verdana, "Arial Black", sans-serif;
	font-size: 16px;
	position: absolute;
	padding: 0px 2px 0px 2px;
	z-index: 1;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	transition: font-size 0.3s ease-out;
	-webkit-transition: font-size 0.3s ease-out;
	-moz-transition: font-size 0.3s ease-out;
	-ms-transition: font-size 0.3s ease-out;
	-o-transition: font-size 0.3s ease-out;
    margin-top: 12px;
    margin-bottom: 12px;
}

.right {
	right: 0px;
}

.bottom {
	bottom: 0px;
}

.white {
	color: white;
}
